import { GlobalNavigation } from "./GlobalNavigation";

export class GlobalHeader {
    private static instance: GlobalHeader;

    private $elm: JQuery;
    private $menu: JQuery;
    private $logo: JQuery;
    private $facebook: JQuery;
    private border: number;
    private color: string;
    private change: boolean;

    private constructor() {
        this.$elm = $('#js-globalHeader');
        this.$menu = this.$elm.find('.navigationIcon--menu');
        this.$logo = this.$elm.find('#js-logo');
        this.$facebook = this.$elm.find('#js-facebook');

        this.$menu.on('click', () => {
            this.open();
        });

        this.change = Boolean(this.$elm.attr('data-change'));
        this.color = this.$elm.attr('data-color');
    }

    public static getInstance(): GlobalHeader {
        if (!this.instance) {
            this.instance = new GlobalHeader();
        }
        return this.instance;
    }

    public resize(): void {
        this.border = $('#js-border').offset().top;
        console.log(this.border);
    }

    public open(): void {
        GlobalNavigation.getInstance().open();
    }

    public close(): void {
        GlobalNavigation.getInstance().close();
    }

    public getIsOpened(): boolean {
        return GlobalNavigation.getInstance().getIsOpened();
    }

    public updateByScroll(scrollTop: number): void {
        if (!this.change) return;

        const logoClass: string = 'logo--black';
        const facebookClass: string = 'navigationIcon--facebook--black';
        const menuClass: string = 'navigationIcon--menu--black';
        if (scrollTop > this.border) {
            this.$logo.addClass(logoClass);
            this.$facebook.addClass(facebookClass);
            this.$menu.addClass(menuClass);
        }
        else {
            this.$logo.removeClass(logoClass);
            this.$facebook.removeClass(facebookClass);
            this.$menu.removeClass(menuClass);
        }
    }
}
