import { GlitchText } from "./view/GlitchText";
import { GlobalHeader } from "./view/GlobalHeader";
import { WorksItem } from "./view/WorksItem";
import { ScrollFiredObjectManager } from "../umadash.js/view/ScrollFiredObjectManager";
import { ScrollFiredObject } from "../umadash.js/view/ScrollFiredObject";
import { WindowWatcher } from "../umadash.js/util/WindowWatcher";
import { Background } from "./view/Background";
import { Slideshow } from "./view/Slideshow";
const $ = jQuery;

export class EntryCommon {

    private texts: GlitchText[];
    private slideshow: Slideshow;
    private bg: Background;

    private scrollHandler: () => void;
    private resizeHandler: () => void;

    constructor() {

        GlobalHeader.getInstance();

        this.scrollHandler = this.scroll.bind(this);
        this.resizeHandler = this.resize.bind(this);

        // slideshow
        const $slideshow = $('#js-slideshow');
        if ($slideshow.length > 0) {
            this.slideshow = new Slideshow($slideshow);
        }

        // bg
        this.bg = new Background($('#js-bg'));


        // texts
        this.texts = [];
        const glitches: JQuery = $('.js-glitch');
        glitches.each((index: number, element: HTMLElement) => {
            this.texts.push(new GlitchText($(element)));
        });
    }


    public run(): void {
        WindowWatcher.getInstance().addEventListener(WindowWatcher.Scroll, this.scrollHandler);
        WindowWatcher.getInstance().addEventListener(WindowWatcher.Resize, this.resizeHandler);

        for (let i = 0; i < this.texts.length; i += 1) {
            const t: GlitchText = this.texts[i];
            t.initialize(() => {
                t.show();
            });
        }

        if (this.slideshow) this.slideshow.show();
        this.bg.show();

        this.resize();
        this.scroll();

        WindowWatcher.getInstance().start();
    }

    private resize(): void {

        for (let i = 0; i < this.texts.length; i += 1) {
            const t: GlitchText = this.texts[i];
            t.resize();
        }


        if (this.slideshow) this.slideshow.resize();
        this.bg.resize();

        GlobalHeader.getInstance().resize();
    }


    private scroll(): void {
        // console.log('scroll');
        const scrollTop: number = window.pageYOffset;
        GlobalHeader.getInstance().updateByScroll(scrollTop);
    }
}