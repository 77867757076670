import * as THREE from 'three';
const vertexShader = require('./vert.glsl');
const fragmentShader = require('./frag.glsl');



export default class GlitchTextMesh extends THREE.Mesh {

  private src: string;
  private uniforms: any;

  constructor(src: string, width: number, height: number) {
    super();

    this.src = src;

    this.uniforms = {
      uTime: { type: 'f', value: 0 },
      uResolution: { type: 'fv', value: [0, 0] },
      uTextureResolution: { type: 'fv', value: [width, height] },
      uTexture: {
        type: 't', value: new THREE.TextureLoader().load(src, (t: THREE.Texture) => {
          t.magFilter = THREE.NearestFilter;
          t.minFilter = THREE.NearestFilter;
        })
      },
    };

    this.material = new THREE.RawShaderMaterial({
      vertexShader: vertexShader.default,
      fragmentShader: fragmentShader.default,
      transparent: true,
      uniforms: this.uniforms,
    });
  }

  public resize(width: number, height: number, sceneWidth: number, sceneHeight: number) {
    this.uniforms.uResolution.value = [width, height];
    this.geometry = new THREE.PlaneBufferGeometry(sceneWidth, sceneHeight, 1, 1);
  }

  public update(time: number) {
    this.uniforms.uTime.value = time;
  }


}
