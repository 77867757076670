import * as THREE from 'three';
const vertexShader = require('./vert.glsl');
const fragmentShader = require('./frag.glsl');



export default class BackgroundMesh extends THREE.Mesh {

    private src: string;
    private uniforms: any;

    constructor() {
        super();


        this.uniforms = {
            uTime: { type: 'f', value: 0 },
            uResolution: { type: 'fv', value: [0, 0] },
        };

        this.material = new THREE.RawShaderMaterial({
            vertexShader: vertexShader.default,
            fragmentShader: fragmentShader.default,
            transparent: true,
            uniforms: this.uniforms,
        });
    }

    public resize(width: number, height: number, sceneWidth: number, sceneHeight: number) {
        this.uniforms.uResolution.value = [width, height];
        this.geometry = new THREE.PlaneBufferGeometry(sceneWidth, sceneHeight, 1, 1);
    }

    public update(time: number) {
        this.uniforms.uTime.value = time;
    }


}
