import BackgroundMesh from './BackgroundMesh/index';
import { GLStage2D } from './GLStage2D';
import { Command } from '../../umadash.js/command/Command';
import { CommandUtil } from '../../umadash.js/util/CommandUtil';
import { Func } from '../../umadash.js/command/Func';

export class Background extends GLStage2D {

    private $elm: JQuery;
    private mesh: BackgroundMesh;


    constructor($elm) {
        super($elm);

        this.$elm = $elm;

        this.mesh = new BackgroundMesh();
        this.scene.add(this.mesh);
    }

    protected getShowCommand(execute: boolean): Command {
        return CommandUtil.serial([
            new Func(() => {
                this.resize();
                this.startRendering();
            })
        ], execute);
    }

    protected getHideCommand(execute: boolean): Command {
        return CommandUtil.serial([
            new Func(() => {
                this.stopRendering();
            })

        ], execute);
    }

    protected implResize() {
        if (this.mesh) {
            this.mesh.resize(this.width, this.height, this.sceneWidth, this.sceneHeight);
        }
    }

    protected implUpdate(): void {
        const time: number = performance.now() * .001;
        this.mesh.update(time);
        this.renderer.render(this.scene, this.camera);
    }
}