import GlitchTextMesh from './GlitchTextMesh/index';
import { GLStage2D } from './GLStage2D';
import { Command } from '../../umadash.js/command/Command';
import { CommandUtil } from '../../umadash.js/util/CommandUtil';
import { Func } from '../../umadash.js/command/Func';
import { DeviceUtil } from '../../umadash.js/util/DeviceUtil';

export class GlitchText extends GLStage2D {

    private $elm: JQuery;

    private mesh: GlitchTextMesh;

    private imageWidth: number;
    private imageHeight: number;


    constructor($elm) {
        super($elm);

        this.$elm = $elm;
    }

    public initialize(callback: () => void) {
        const srcPc: string = this.$elm.attr('data-src');
        const srcSp: string = this.$elm.attr('data-src-sp');
        const src: string = (srcSp && !DeviceUtil.isDesktop()) ? srcSp : srcPc;

        if (src) {
            this.loadImage(src,
                (e) => {
                    this.imageWidth = e.target.width;
                    this.imageHeight = e.target.height;

                    const ratio: number = DeviceUtil.isDesktop() ? 1.0 : .5;
                    this.$elm.css({
                        width: '100%',
                        height: this.imageHeight * ratio
                    });


                    this.mesh = new GlitchTextMesh(src, this.imageWidth * ratio, this.imageHeight * ratio);
                    this.scene.add(this.mesh);

                    callback();
                },
                () => {
                    console.warn('error load');
                }
            );
        }
        else {
            console.warn('no image');
        }
    }

    private loadImage(url: string, onComplete: (e) => void, onError: () => void): void {
        const image: HTMLImageElement = new Image();
        image.onload = onComplete;
        image.onerror = onError;
        image.src = url;
    }

    protected getShowCommand(execute: boolean): Command {
        return CommandUtil.serial([
            new Func(() => {
                this.resize();
                this.startRendering();
            })
        ], execute);
    }

    protected getHideCommand(execute: boolean): Command {
        return CommandUtil.serial([
            new Func(() => {
                this.stopRendering();
            })

        ], execute);
    }

    protected implResize() {
        if (this.mesh) {
            this.mesh.resize(this.width, this.height, this.sceneWidth, this.sceneHeight);
        }
    }

    protected implUpdate(): void {

        const time: number = performance.now() * .001;
        this.mesh.update(time);
        this.renderer.render(this.scene, this.camera);
    }
}