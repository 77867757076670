
export class GlobalNavigation {
    private static instance: GlobalNavigation;

    private $elm: JQuery;
    private $menu: JQuery;

    private constructor() {
        this.$elm = $('#js-navigation');
        this.$menu = this.$elm.find('.navigationIcon--menu');

        this.$menu.on('click', () => {
            this.close();
        });
    }

    public static getInstance(): GlobalNavigation {
        if (!this.instance) {
            this.instance = new GlobalNavigation();
        }
        return this.instance;
    }

    public open(): void {
        this.$elm.addClass('js-open');
    }

    public close(): void {
        this.$elm.removeClass('js-open');
    }

    public getIsOpened(): boolean {
        return this.$elm.hasClass('js-open');
    }
}
